import { THrsa, THrsas } from '../type/hrsa'
import { customErrorResponse } from '../utility/custom-error-response'
import { request, setCustomOnResponseError, resetCustomOnResponseError } from './axios'

export const HRSA = {
  get: async (search_key = '', page_number = 1): Promise<THrsas> => {
    const searchParam = search_key ? `&search_key=${search_key}` : ''
    const {
      data: { results, max_page_size, page_no, total_count, total_pages },
    } = await request({
      url: `/hrsa-audits/audits/?page_number=${page_number}${searchParam}`,
    })

    return {
      results,
      max_page_size,
      page_no,
      total_count,
      total_pages,
    }
  },

  create: async (hrsa_audit: THrsa) => {
    setCustomOnResponseError(customErrorResponse)
    return request({
      method: 'post',
      url: '/hrsa-audits/audit/',
      data: hrsa_audit,
    }).finally(() => {
      resetCustomOnResponseError()
    })
  },
}
