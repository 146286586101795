import { lazy } from 'react'

export const View = {
  Home: lazy(() => import(/*webpackChunkName:'home'*/ './home').then(({ Home }) => ({ default: Home }))),
  Hrsas: lazy(() => import(/*webpackChunkName:'home'*/ './hrsa').then(({ HrsasPage }) => ({ default: HrsasPage }))),
  // Hrsa: lazy(() => import(/*webpackChunkName:'home'*/ './hrsa').then(({ HrsaPage }) => ({ default: HrsaPage }))),
  Upload: {
    Wrap: lazy(() => import(/*webpackChunkName:'upload'*/ './upload').then(({ Wrap }) => ({ default: Wrap }))),
    Upload: lazy(() => import(/*webpackChunkName:'upload'*/ './upload').then(({ Upload }) => ({ default: Upload }))),
    History: lazy(() => import(/*webpackChunkName:'upload'*/ './upload').then(({ History }) => ({ default: History }))),
  },
  DataInput: {
    NpiEligibility: lazy(() =>
      import(/*webpackChunkName:'npi-elegibility'*/ './data-input').then(({ NpiEligibility }) => ({ default: NpiEligibility }))
    ),
  },
  PatientSource: {
    SourceManagement: lazy(() =>
      import(/*webpackChunkName:'source-management'*/ './patient-source').then(({ SourceManagement }) => ({ default: SourceManagement }))
    ),
    PatientSourcePage: lazy(() =>
      import(/*webpackChunkName:'source-management'*/ './patient-source').then(({ PatientSourcePage }) => ({
        default: PatientSourcePage,
      }))
    ),
  },
  FeeCalculation: {
    FieldConfiguration: lazy(() =>
      import(/*webpackChunkName:'fee-calculation'*/ './fee-calculation').then(({ FieldConfiguration }) => ({ default: FieldConfiguration }))
    ),
    Flags: lazy(() => import(/*webpackChunkName:'fee-calculation'*/ './fee-calculation').then(({ Flags }) => ({ default: Flags }))),
    Rates: lazy(() => import(/*webpackChunkName:'fee-calculation'*/ './fee-calculation').then(({ Rates }) => ({ default: Rates }))),
    Ranges: lazy(() => import(/*webpackChunkName:'fee-calculation'*/ './fee-calculation').then(({ Ranges }) => ({ default: Ranges }))),
    FeeManagement: lazy(() =>
      import(/*webpackChunkName:'fee-calculation'*/ './fee-calculation').then(({ FeeManagement }) => ({ default: FeeManagement }))
    ),
    CoveredEntities: lazy(() =>
      import(/*webpackChunkName:'fee-calculation'*/ './fee-calculation').then(({ CoveredEntities }) => ({ default: CoveredEntities }))
    ),
    Drafts: lazy(() => import(/*webpackChunkName:'fee-calculation'*/ './fee-calculation').then(({ Drafts }) => ({ default: Drafts }))),
    Mappings: lazy(() =>
      import(/*webpackChunkName:'fee-calculation'*/ './fee-calculation').then(({ Mappings }) => ({ default: Mappings }))
    ),
  },
  Support: lazy(() => import(/*webpackChunkName:'support'*/ './support').then(({ Support }) => ({ default: Support }))),
  Admin: {
    CategoryClassifications: lazy(() =>
      import(/*webpackChunkName:'date-input'*/ './admin/category-classification').then(({ CategoryClassifications }) => ({
        default: CategoryClassifications,
      }))
    ),
    CategoryClassification: lazy(() =>
      import(/*webpackChunkName:'date-input'*/ './admin/category-classification').then(({ CategoryClassification }) => ({
        default: CategoryClassification,
      }))
    ),
    Synchronization: lazy(() =>
      import(/*webpackChunkName:'admin'*/ './admin').then(({ admin: { Synchronization } }) => ({ default: Synchronization }))
    ),
    UserRolesManagement: lazy(() =>
      import(/*webpackChunkName:'admin'*/ './admin').then(({ admin: { UserRolesManagement } }) => ({ default: UserRolesManagement }))
    ),
    Permissions: lazy(() =>
      import(/*webpackChunkName:'admin'*/ './admin').then(({ admin: { Permissions } }) => ({ default: Permissions }))
    ),
    Resources: lazy(() => import(/*webpackChunkName:'admin'*/ './admin').then(({ admin: { Resources } }) => ({ default: Resources }))),
    AuxRulesManagement: lazy(() =>
      import(/*webpackChunkName:'admin'*/ './admin').then(({ admin: { AuxRulesManagement } }) => ({ default: AuxRulesManagement }))
    ),
    ValidationRules: lazy(() =>
      import(/*webpackChunkName:'admin'*/ './admin').then(({ admin: { ValidationRules } }) => ({ default: ValidationRules }))
    ),
    UploadTemplate: lazy(() =>
      import(/*webpackChunkName:'admin'*/ './admin').then(({ admin: { UploadTemplate } }) => ({ default: UploadTemplate }))
    ),
    ADManagement: {
      Users: lazy(() =>
        import(/*webpackChunkName:'admin'*/ './admin').then(
          ({
            admin: {
              ADManagement: { UsersTabs },
            },
          }) => ({ default: UsersTabs })
        )
      ),
      ExternalUser: lazy(() =>
        import(/*webpackChunkName:'admin'*/ './admin').then(
          ({
            admin: {
              ADManagement: { ExternalUser },
            },
          }) => ({ default: ExternalUser })
        )
      ),
      InternalUser: lazy(() =>
        import(/*webpackChunkName:'admin'*/ './admin').then(
          ({
            admin: {
              ADManagement: { InternalUser },
            },
          }) => ({ default: InternalUser })
        )
      ),
      CoveredEntities: lazy(() =>
        import(/*webpackChunkName:'admin'*/ './admin').then(
          ({
            admin: {
              ADManagement: { CoveredEntities },
            },
          }) => ({ default: CoveredEntities })
        )
      ),
      CoveredEntity: lazy(() =>
        import(/*webpackChunkName:'admin'*/ './admin').then(
          ({
            admin: {
              ADManagement: { CoveredEntity },
            },
          }) => ({ default: CoveredEntity })
        )
      ),
      Memberships: lazy(() =>
        import(/*webpackChunkName:'admin'*/ './admin').then(
          ({
            admin: {
              ADManagement: { MembershipsTabs },
            },
          }) => ({ default: MembershipsTabs })
        )
      ),
      Membership: lazy(() =>
        import(/*webpackChunkName:'admin'*/ './admin').then(
          ({
            admin: {
              ADManagement: { Membership },
            },
          }) => ({ default: Membership })
        )
      ),
      StreamsAndApps: lazy(() =>
        import(/*webpackChunkName:'admin'*/ './admin').then(
          ({
            admin: {
              ADManagement: { StreamsAndAppsTabs },
            },
          }) => ({ default: StreamsAndAppsTabs })
        )
      ),
      StreamApp: lazy(() =>
        import(/*webpackChunkName:'admin'*/ './admin').then(
          ({
            admin: {
              ADManagement: { StreamApp },
            },
          }) => ({ default: StreamApp })
        )
      ),
      SharedGroups: lazy(() =>
        import(/*webpackChunkName:'admin'*/ './admin').then(
          ({
            admin: {
              ADManagement: { SharedGroupsTabs },
            },
          }) => ({ default: SharedGroupsTabs })
        )
      ),
      SharedGroup: lazy(() =>
        import(/*webpackChunkName:'admin'*/ './admin').then(
          ({
            admin: {
              ADManagement: { SharedGroup },
            },
          }) => ({ default: SharedGroup })
        )
      ),
    },
  },
  Pharmasee: lazy(() => import(/*webpackChunkName:'pharmasee'*/ './pharmasee').then(({ Pharmasee }) => ({ default: Pharmasee }))),
  Sandbox: lazy(() => import(/*webpackChunkName:'sandbox'*/ './sandbox').then(({ Sandbox }) => ({ default: Sandbox }))),
}
